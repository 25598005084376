<template>
    <div>
        <section class="min-w-screen animation-fade animation-delay relative bg-white">
            <div class="sm:px-12 xl:px-5 container px-0 px-8 mx-auto">
                <p
                    class="sm:mx-6 sm:text-center sm:text-normal sm:font-bold text-xs font-bold text-left text-pink-500 uppercase"
                >
                    Got a Question? We’ve got answers.
                </p>
                <h3
                    class="sm:mx-6 sm:text-3xl md:text-4xl lg:text-5xl sm:text-center sm:mx-0 mt-1 text-2xl font-bold text-left text-gray-800"
                >
                    Frequently Asked Questions
                </h3>
                <div
                    class="sm:px-8 md:px-12 sm:py-8 sm:shadow lg:w-5/6 xl:w-2/3 w-full px-6 py-6 mx-auto mt-10 bg-white border border-gray-200 rounded-lg"
                >
                    <h3 class="sm:text-xl md:text-2xl text-lg font-bold text-purple-500">How does it work?</h3>
                    <p class="sm:text-lg md:text-normal mt-2 text-base text-gray-600">
                        Simple! Head over to
                        <router-link :to="{ name: 'create' }" class="underline">Create Token</router-link>
                        and fill out the required information. When reaching the last step, you'll be asked to confirm
                        your contract deployment and transaction within your web3 wallet. After confirmation, your
                        contract will be deployed within 1 minute(Usually 10 seconds or lower).
                    </p>
                </div>
                <div
                    class="sm:px-8 md:px-12 sm:py-8 sm:shadow lg:w-5/6 xl:w-2/3 w-full px-6 py-6 mx-auto mt-10 bg-white border border-gray-200 rounded-lg"
                >
                    <h3 class="sm:text-xl md:text-2xl text-lg font-bold text-purple-500">
                        Does tokengen have access to my wallet?
                    </h3>
                    <p class="sm:text-lg md:text-normal mt-2 text-base text-gray-600">
                        Absolutely not! Your wallet is encrypted and only you have access. There is absolutely no
                        connection between tokengen and your wallet at any point. The transaction is generated in the
                        BEP20 network and sent to your wallet to be confirmed.
                    </p>
                </div>
                <div
                    class="sm:px-8 md:px-12 sm:py-8 sm:shadow lg:w-5/6 xl:w-2/3 w-full px-6 py-6 mx-auto mt-10 bg-white border border-gray-200 rounded-lg"
                >
                    <h3 class="sm:text-xl md:text-2xl text-lg font-bold text-purple-500">
                        How does contract verification work?
                    </h3>
                    <div class="sm:text-lg md:text-normal mt-2 text-base text-gray-600">
                        <p>All contracts deployed from tokengen will <i>always</i> be verified by "Similar Match".</p>
                        <p>
                            If you want your contract verified with an exact match an additional charge of
                            {{ settings.priceForVerification }} BNB will be added.
                        </p>

                        <a
                            href="https://ethereum.stackexchange.com/a/64727"
                            class="text-blue-500 underline"
                            target="_blank"
                            rel="noopener"
                            >Check this StackExchange answer for how Similar Match works</a
                        >
                    </div>
                </div>
                <div
                    class="sm:px-8 md:px-12 sm:py-8 sm:shadow lg:w-5/6 xl:w-2/3 w-full px-6 py-6 mx-auto mt-10 bg-white border border-gray-200 rounded-lg"
                >
                    <h3 class="sm:text-xl md:text-2xl text-lg font-bold text-purple-500">
                        Do you offer custom contracts?
                    </h3>
                    <p class="sm:text-lg md:text-normal mt-2 text-base text-gray-600">
                        Of course! If you're interested in a custom contract for your token, please don't hesitate to
                        send us an email on
                        <a href="mailto:contact@tokengen.net" class="text-blue-500 underline">contact@tokengen.net</a>.
                        We typically respond within 24 hours.
                    </p>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
export default {
    name: 'FAQ',
};
</script>

<style scoped></style>
